define('m14/components/tex-t', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var x3dText = Ember['default'].Component.extend({
    tagName: 'transform',
    layoutName: 'tex-t',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['testClassNameXXX'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    center: "0 0 0",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",

    austrittspunktRechts: false,

    translation: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var typ = this.get('typ');

      var x = 0;
      var y = 0;
      var z = 0;

      var cords;
      var skalierungsfaktor = x3d.get('skalierungsfaktor');
      var item = x3d.get('bauteile').findBy('id', name);

      var zVerschiebung = Number(bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('z')) / 20;

      var hoeheMasskette = 0.5;
      var abstandVomBauteil = 0.15;

      var abstandVonDerMasslinie = 0.05;

      var hoeheLastenpfeil = bauteile.findBy('id', 'gleichlast1').get('boxsizes').objectAt(0).get('y') + 0.1;

      switch (typ) {
        case 'breite':
          z = 0;

          if (name === 'material1') {
            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              x = -Number(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20) - abstandVomBauteil - hoeheMasskette - abstandVonDerMasslinie;
              y = -0.2 - bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10;
            } else {
              x = -Number(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20);
              y = -Number(bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10) - abstandVomBauteil - hoeheMasskette + abstandVonDerMasslinie;
            }
          } else if (name === 'material22' || name === 'uProfil2') {
            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              x = Number(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20) + abstandVomBauteil + hoeheMasskette + abstandVonDerMasslinie;
              y = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 10;
            } else {
              x = Number(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20);
              y = Number(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 10) + abstandVomBauteil + hoeheMasskette + abstandVonDerMasslinie;
            }
          }

          break;
        case 'hoehe':
          if (name === 'material1') {
            if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
              x = -Number(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20);
              y = Number(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 20);
              z = -zVerschiebung - abstandVomBauteil - hoeheMasskette - abstandVonDerMasslinie;

              if (x3d.get('zweiseitigeVerstaerkung') === true && x3d.get('stahlVerstaerkung') === true && x3d.get('istProfilstahl') === true) {
                z = z - Number(bauteile.findBy('id', 'uProfil2').get('boxsizes').objectAt(0).get('z') / 10);
              } else if (x3d.get('zweiseitigeVerstaerkung') === true) {
                z = z - Number(bauteile.findBy('id', 'material22').get('boxsizes').objectAt(0).get('z') / 10);
              }
            } else {
              x = -Number(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20) - abstandVomBauteil - hoeheMasskette - abstandVonDerMasslinie;
              y = Number(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 20);
              z = -Number(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20);
            }
          } else if (name === 'material22' || name === 'uProfil2') {
            if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
              x = Number(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20);
              y = Number(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 20);
              z = Number(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20) + abstandVomBauteil + hoeheMasskette + abstandVonDerMasslinie;
            } else {
              x = Number(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20) + abstandVomBauteil + hoeheMasskette + abstandVonDerMasslinie;
              y = Number(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 20);
              z = Number(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20);
            }
          }

          break;
        case 'laenge':
          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            x = 0;
            y = Number(bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10) + Number(bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('y') / 10);

            if (x3d.get('zweiseitigeVerstaerkung') === true && x3d.get('stahlVerstaerkung') === true && x3d.get('istProfilstahl') === true) {
              zVerschiebung = zVerschiebung + Number(bauteile.findBy('id', 'uProfil2').get('boxsizes').objectAt(0).get('z') / 10);
            } else if (x3d.get('zweiseitigeVerstaerkung') === true) {
              zVerschiebung = zVerschiebung + Number(bauteile.findBy('id', 'material22').get('boxsizes').objectAt(0).get('z') / 10);
            }

            z = -zVerschiebung - abstandVomBauteil - hoeheMasskette - abstandVonDerMasslinie;
          } else {
            x = 0;
            y = hoeheLastenpfeil + abstandVomBauteil + hoeheMasskette + abstandVonDerMasslinie + Number(bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10) + Number(bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('y') / 10);
            z = -Number(bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('z') / 20);
          }

          break;

      }

      if (item.get('typ') === 'standardSchraube') {

        var y = bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10 + bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('y') / 10;

        if (x3d.get('zweiseitigeVerstaerkung') === true && x3d.get('stahlVerstaerkung') === true && x3d.get('istProfilstahl') === true) {
          zVerschiebung = Number(bauteile.findBy('id', 'uProfil2').get('boxsizes').objectAt(0).get('z') / 10);
        } else if (x3d.get('zweiseitigeVerstaerkung') === true) {
          zVerschiebung = Number(bauteile.findBy('id', 'material22').get('boxsizes').objectAt(0).get('z') / 10);
        } else {
          zVerschiebung = 0;
        }

        switch (typ) {
          case 'randAbstand1':
            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              x = -(bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('x') / 10) + (bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('x') / 10 - Number(x3d.get('xPosMax')) / 10) / 2;
              y = y;
              z = -zVerschiebung - bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('z') / 20 - 3 * abstandVomBauteil - 2 * hoeheMasskette - abstandVonDerMasslinie;
            } else {
              x = -(bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('x') / 10) + (bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('x') / 10 - Number(x3d.get('xPosMax')) / 10) / 2;
              y = y + hoeheLastenpfeil + 2 * abstandVomBauteil + 2 * hoeheMasskette + abstandVonDerMasslinie;
              z = -(bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('z') / 20);
            }
            break;
          case 'schraubenAbstand1':
            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              x = -Number(x3d.get('xPosMin')) / 10 - (Number(x3d.get('xPosMax')) - Number(x3d.get('xPosMin'))) / 20;
              y = y;
              z = -zVerschiebung - bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('z') / 20 - 3 * abstandVomBauteil - 2 * hoeheMasskette - abstandVonDerMasslinie;
            } else {
              x = -Number(x3d.get('xPosMin')) / 10 - (Number(x3d.get('xPosMax')) - Number(x3d.get('xPosMin'))) / 20;
              y = y + hoeheLastenpfeil + 2 * abstandVomBauteil + 2 * hoeheMasskette + abstandVonDerMasslinie;
              z = -(bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('z') / 20);
            }
            break;
          case 'AbstandMitte':
            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              x = 0;
              y = y;
              z = -zVerschiebung - bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('z') / 20 - 3 * abstandVomBauteil - 2 * hoeheMasskette - abstandVonDerMasslinie;
            } else {
              x = 0;
              y = y + hoeheLastenpfeil + 2 * abstandVomBauteil + 2 * hoeheMasskette + abstandVonDerMasslinie;
              z = -(bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('z') / 20);
            }
            break;
          case 'schraubenAbstand2':
            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              x = Number(x3d.get('xPosMin')) / 10 + (Number(x3d.get('xPosMax')) - Number(x3d.get('xPosMin'))) / 20;
              y = y;
              z = -zVerschiebung - bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('z') / 20 - 3 * abstandVomBauteil - 2 * hoeheMasskette - abstandVonDerMasslinie;
            } else {
              x = Number(x3d.get('xPosMin')) / 10 + (Number(x3d.get('xPosMax')) - Number(x3d.get('xPosMin'))) / 20;
              y = y + hoeheLastenpfeil + 2 * abstandVomBauteil + 2 * hoeheMasskette + abstandVonDerMasslinie;
              z = -(bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('z') / 20);
            }
            break;
          case 'randAbstand2':
            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              x = bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('x') / 10 - (bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('x') / 10 - Number(x3d.get('xPosMax')) / 10) / 2;
              y = y;
              z = -zVerschiebung - bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('z') / 20 - 3 * abstandVomBauteil - 2 * hoeheMasskette - abstandVonDerMasslinie;
            } else {
              x = bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('x') / 10 - (bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('x') / 10 - Number(x3d.get('xPosMax')) / 10) / 2;
              y = y + hoeheLastenpfeil + 2 * abstandVomBauteil + 2 * hoeheMasskette + abstandVonDerMasslinie;
              z = -(bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('z') / 20);
            }
            break;
          case 'randAbstand3':
            if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
              x = -Number(bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('x') / 10) - 0.1;
              y = Number(bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10) + Number(bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('y') / 10) - Number(x3d.get('deltaY')) / 20;
              z = -Number(bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('z') / 20) - zVerschiebung - 2 * hoeheMasskette - 2 * abstandVomBauteil - abstandVonDerMasslinie;
            } else {
              x = -Number(bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('x') / 10) - 0.1 - 2 * hoeheMasskette - 2 * abstandVomBauteil - abstandVonDerMasslinie;
              y = Number(bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10) + Number(bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('y') / 10) - Number(x3d.get('deltaY')) / 20;
              z = -Number(bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('z') / 20);
            }
            break;
          case 'randAbstand4':
            if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
              x = -Number(bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('x') / 10) - 0.1;
              y = Number(bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10) + Number(x3d.get('deltaY')) / 20;
              z = -Number(bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('z') / 20) - zVerschiebung - 2 * hoeheMasskette - 2 * abstandVomBauteil - abstandVonDerMasslinie;
            } else {
              x = -Number(bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('x') / 10) - 0.1 - 2 * hoeheMasskette - 2 * abstandVomBauteil - abstandVonDerMasslinie;
              y = Number(bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10) + Number(x3d.get('deltaY')) / 20;
              z = -Number(bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('z') / 20);
            }
            break;
          case 'schraubenAbstand3':

            if (x3d.get('stahlVerstaerkung') === true && x3d.get('istProfilstahl') === true) {
              zVerschiebung = Number(bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('z') / 20) + Number(bauteile.findBy('id', 'uProfil2').get('boxsizes').objectAt(0).get('z') / 10);
            } else {
              zVerschiebung = Number(bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('z') / 20) + Number(bauteile.findBy('id', 'material22').get('boxsizes').objectAt(0).get('z') / 10);
            }

            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              x = -Number(x3d.get('xPosMax')) / 10;
              y = Number(bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y')) / 10 - 0.1;
              z = zVerschiebung + abstandVomBauteil + hoeheMasskette + abstandVonDerMasslinie;
            } else {
              x = -Number(x3d.get('xPosMax')) / 10;
              y = -0.1 - abstandVomBauteil - hoeheMasskette - abstandVonDerMasslinie;
              z = zVerschiebung;
            }
            break;
        }
      }

      if (name.substring(0, 11) === 'lastenPfeil') {
        y = -1.25;
      }

      cords = x + ' ' + y + ' ' + z;
      return cords;
    }).property('model.firstObject.transformHelper', 'model.firstObject.skalierungsfaktor', 'model.firstObject.schraubenGekreuzt', 'model.firstObject.schraubenlisteAktualisiert', 'model.firstObject.viewpointHelper'),

    rotation: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var typ = this.get('typ');
      var values;
      var x = 0,
          y = 0,
          z = 0,
          omega = 0;
      var w90Grad = 90 * Math.PI / 180;
      var w240Grad = 240 * Math.PI / 180;
      var item = x3d.get('bauteile').findBy('id', name);

      switch (typ) {
        case 'breite':
          if (name === 'material1') {
            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              x = 1;
              y = -1;
              z = -1;
              omega = w240Grad;
            } else {
              x = 0;
              y = 1;
              z = 0;
              omega = -w90Grad;
            }
          } else if (name === 'material22' || name === 'uProfil2') {
            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              x = 1;
              y = 1;
              z = 1;
              omega = w240Grad;
            } else {
              x = 0;
              y = 1;
              z = 0;
              omega = -w90Grad;
            }
          }
          break;
        case 'hoehe':

          if (name === 'material1') {
            if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
              x = 1;
              y = 1;
              z = -1;
              omega = w240Grad;
            } else {
              x = 0;
              y = 0;
              z = 1;
              omega = w90Grad;
            }
          } else if (name === 'material22' || name === 'uProfil2') {
            if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
              x = -1;
              y = 1;
              z = 1;
              omega = w240Grad;
            } else {
              x = 0;
              y = 0;
              z = 1;
              omega = -w90Grad;
            }
          }

          break;
        case 'laenge':
          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            x = 1;
            y = 0;
            z = 0;
            omega = -w90Grad;
          } else {
            x = 0;
            y = 0;
            z = 1;
            omega = 0;
          }
          break;
        case 'randAbstand1':
          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            x = 1;
            y = 0;
            z = 0;
            omega = -w90Grad;
          }
          break;
        case 'schraubenAbstand1':
          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            x = 1;
            y = 0;
            z = 0;
            omega = -w90Grad;
          }
          break;
        case 'AbstandMitte':
          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            x = 1;
            y = 0;
            z = 0;
            omega = -w90Grad;
          }
          break;
        case 'schraubenAbstand2':
          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            x = 1;
            y = 0;
            z = 0;
            omega = -w90Grad;
          }
          break;
        case 'randAbstand2':
          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            x = 1;
            y = 0;
            z = 0;
            omega = -w90Grad;
          }
          break;
        case 'randAbstand3':
          if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
            x = 1;
            y = 1;
            z = -1;
            omega = w240Grad;
          } else {
            x = 0;
            y = 0;
            z = 1;
            omega = w90Grad;
          }
          break;
        case 'randAbstand4':
          if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
            x = 1;
            y = 1;
            z = -1;
            omega = w240Grad;
          } else {
            x = 0;
            y = 0;
            z = 1;
            omega = w90Grad;
          }
          break;
        case 'schraubenAbstand3':
          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            x = 1;
            y = 0;
            z = 0;
            omega = -w90Grad;
          } else {
            x = 0;
            y = 0;
            z = 0;
            omega = 0;
          }
          break;
      }

      values = x + ' ' + y + ' ' + z + ' ' + omega;

      // if ( typ === 'randAbstand3' || typ === 'schraubenAbstand3' | typ === 'randAbstand4'){
      //   x = 0;
      //   y = 1;
      //   z = 0;
      //   omega = -w90Grad;
      // }

      if (name.substring(0, 11) === 'lastenPfeil') {
        values = '0 0 0 0';
      }

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.istHt', 'model.firstObject.viewpointHelper'),

    wert: (function () {

      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var value = ' ';

      var item = x3d.get('bauteile').findBy('id', name);

      switch (typ) {
        case 'breite':
          if (name === 'material1' && x3d.get('htBreiteEingetragen')) {
            value = Number(item.get('boxsizes').objectAt(0).get('z')).toFixed(1);
          }
          if (name === 'material22' && x3d.get('breiteMaterial2Eingetragen')) {
            value = Number(item.get('boxsizes').objectAt(0).get('z')).toFixed(1);
          }
          if (name === 'uProfil2') {
            value = Number(item.get('boxsizes').objectAt(0).get('z')).toFixed(1);
          }
          break;
        case 'hoehe':
          if (name === 'material1' && x3d.get('htHoeheEingetragen')) {
            value = Number(item.get('boxsizes').objectAt(0).get('y')).toFixed(1);
          }
          if (name === 'material22' && x3d.get('hoeheMaterial2Eingetragen')) {
            value = Number(item.get('boxsizes').objectAt(0).get('y')).toFixed(1);
          }
          if (name === 'uProfil2') {
            value = Number(item.get('boxsizes').objectAt(0).get('y')).toFixed(1);
          }
          break;
        case 'laenge':
          if (name === 'material1' && x3d.get('systemlaengeEingetragen')) {
            value = Number(x3d.get('systemlaenge')).toFixed(1);
          }
          break;
      }

      if (item.get('typ') === 'standardSchraube') {

        switch (this.get('typ')) {
          case 'randAbstand1':
            value = Number(x3d.get('Randabstand'));
            value = parseFloat(value).toFixed(1);
            break;
          case 'schraubenAbstand1':
            value = Number(x3d.get('a1'));
            value = parseFloat(value).toFixed(1);
            break;
          case 'AbstaendeZusammengefasst1':
            value = x3d.get('AbstaendeZusammengefasst');
            break;
          case 'AbstandMitte':
            value = x3d.get('AbstandMitte');
            break;
          case 'AbstaendeZusammengefasst2':
            value = x3d.get('AbstaendeZusammengefasst');
            break;
          case 'schraubenAbstand2':
            value = Number(x3d.get('a1'));
            value = parseFloat(value).toFixed(1);
            break;
          case 'randAbstand2':
            value = Number(x3d.get('Randabstand'));
            value = parseFloat(value).toFixed(1);
            break;
          case 'randAbstand3':
            value = x3d.get('deltaY');
            value = parseFloat(value).toFixed(1);
            break;
          case 'schraubenAbstand3':
            value = x3d.get('aGruppe');
            value = parseFloat(value).toFixed(1);
            break;
          case 'randAbstand4':
            value = x3d.get('deltaY');
            value = parseFloat(value).toFixed(1);
            break;
        }
      }

      if (name === 'lastenPfeil1') {
        value = x3d.get('querkraft');
        value = parseFloat(value).toFixed(1) + ' kN';
      }

      if (name === 'momentlast') {
        value = x3d.get('momentlast');
        value = parseFloat(value).toFixed(1) + ' kN';
      }

      return value;
    }).property('model.firstObject.transformHelper', 'model.firstObject.schraubenlisteAktualisiert'),

    diffusecolor: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);

      var emsvcolor = '0 0 0';

      if (x3d.get('htbreiteAktiv') === true && name === 'material1' && typ === 'breite') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('hthoeheAktiv') === true && name === 'material1' && typ === 'hoehe') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('breiteMaterial2Aktiv') === true && name === 'material22' && typ === 'breite') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('hoeheMaterial2Aktiv') === true && name === 'material22' && typ === 'hoehe') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('systemlaengeAktiv') === true && name === 'material1' && typ === 'laenge') {
        emsvcolor = '1 0 0';
      }

      return emsvcolor;
    }).property('model.firstObject.htbreiteAktiv', 'model.firstObject.hthoeheAktiv', 'model.firstObject.breiteMaterial2Aktiv', 'model.firstObject.hoeheMaterial2Aktiv', 'model.firstObject.systemlaengeAktiv'),

    textgroesse: (function () {
      var skalierungsfaktor = this.get('model').objectAt(0).get('skalierungsfaktor');
      return 0.3 * skalierungsfaktor;
    }).property('model.firstObject.skalierungsfaktor', 'model.firstObject.transformHelper'),

    getSchraubeKopfPos: function getSchraubeKopfPos(name, achse) {
      var value = 0;

      var x3d = this.get('model').objectAt(0);

      var item = x3d.get('bauteile').findBy('id', name);

      var xVerschiebung = item.get('translations').objectAt(0).get('x') / 10;
      var yVerschiebung = item.get('translations').objectAt(0).get('y') / 10;
      var zVerschiebung = item.get('translations').objectAt(0).get('z') / 10;

      var schraubenlaenge = item.get('screwProperties').objectAt(0).get('length') / 10;
      var alpha = item.get('rotations').objectAt(0).get('z');
      var beta = item.get('rotations').objectAt(0).get('y');

      if (alpha > 1.5707 && !x3d.get('schraubenGekreuzt')) {
        alpha = 1.5707 - (alpha - 1.5707);
        xVerschiebung = xVerschiebung * -1;
      }

      var delta = item.get('screwProperties').objectAt(0).get('headdiameter') / 20 * Math.cos(alpha);

      // var xKopfPos = -(Math.cos(beta)*yVerschiebung/Math.tan(alpha) - xVerschiebung);

      var xKopfPos;

      if (xVerschiebung > 0) {

        var vwinkel = x3d.get('vWinkel');
        var xGesamt = yVerschiebung / Math.tan(alpha - 1.5707);
        var x2 = Math.abs(xGesamt) - xVerschiebung / Math.cos(beta);
        var gamma = Math.PI - (alpha - 1.5707) - vwinkel * Math.PI / 180;
        var a = x2 * Math.sin(alpha - 1.5707) / Math.sin(gamma);
        xKopfPos = Math.cos(vwinkel * Math.PI / 180) * a * -1;
        xKopfPos = Math.cos(beta) * xKopfPos;
      } else {
        xKopfPos = -(Math.cos(beta) * yVerschiebung / Math.tan(alpha) - xVerschiebung);
        if (Number(xKopfPos) > Number(x3d.get('bauteile').findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).get('x') / 10)) {
          this.austrittspunktRechts = true;
          xKopfPos = (xKopfPos - x3d.get('bauteile').findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).get('x') / 10) * Math.sin(alpha);
        }
      }

      var zKopfPos = Math.sin(beta) * (Math.cos(alpha) * schraubenlaenge) - zVerschiebung;

      if (achse === 'x') {
        value = xKopfPos;
      } else if (achse === 'z') {
        value = zKopfPos;
      }

      return value;
    }

  });

  exports['default'] = x3dText;

});